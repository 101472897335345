<template>
  <div>
    <b-row>
      <!-- Avatar -->
      <b-col cols="auto">
        <b-avatar
          size="48px"
          rounded
          variant="light-primary"
        >
          <feather-icon
            icon="RewardIcon"
            size="20"
          />
        </b-avatar>
      </b-col>

      <!-- Content -->
      <b-col>
        <b-row>
          <!-- Title -->
          <b-col>
            <b-row
              no-gutters
              class="mb-50"
              align-v="center"
            >
              <b-col cols="auto">
                <h4
                  v-if="defaultTitle"
                  class="mb-0"
                >
                  {{ defaultTitle }}
                </h4>
                <h4
                  v-else
                  class="text-muted mb-0"
                >
                  <i>No title</i>
                </h4>
              </b-col>
              <!-- Description tooltip -->
              <b-col
                v-if="description"
                cols="auto"
              >
                <feather-icon
                  v-b-tooltip.v-dark.top="description"
                  size="20"
                  icon="InfoIcon"
                  class="text-muted ml-50"
                />
              </b-col>
            </b-row>
          </b-col>
          <!-- Token -->
          <b-col
            v-b-tooltip.v-dark="token ? `USD ${$options.filters.number(token)}` : undefined"
            cols="auto"
            class="mb-0"
          >
            <h4 class="mb-0">
              {{ currency }} {{ price | number }}
            </h4>
          </b-col>
        </b-row>

        <b-row class="mt-25">
          <!-- Created At -->
          <b-col v-if="startedAt && endedAt">
            <span
              v-b-tooltip.v-dark="$options.filters.datetime(startedAt)"
            >{{ startedAt | dateShortYear }}</span> - <span
              v-b-tooltip.v-dark="$options.filters.datetime(endedAt)"
            >{{ endedAt | dateShortYear }}</span>
          </b-col>
          <!-- Token -->
          <b-col
            cols="auto"
          >
            <TableField
              :value="status"
              :map="statusMap"
              type="dot"
              hide-copy
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BAvatar,
} from 'bootstrap-vue'
import TableField from '@/layouts/components/TableField.vue'

export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    TableField,
  },
  props: {
    usecase: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: undefined,
    },
    walletType: {
      type: String,
      default: undefined,
    },
    status: {
      type: String,
      required: true,
    },
    token: {
      type: Number,
      required: true,
    },
    supporterUsername: {
      type: String,
      default: '',
    },
    supporterId: {
      type: String,
      default: '',
    },
    createdAt: {
      type: String,
      required: true,
    },
    startedAt: {
      type: String,
      default: null,
    },
    endedAt: {
      type: String,
      default: null,
    },
    price: {
      type: Number,
      default: undefined,
    },
    currency: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      statusMap: {
        CREATED: 'warning',
        FAILED: 'danger',
        CREATED_CANCELLED: 'danger',
        SUCCEED: 'success',
      },
    }
  },
  computed: {
    defaultTitle() {
      if (this.usecase === 'USER_MEMBERSHIP_RECURRING') {
        return 'Membership Recurring'
      }

      if (this.usecase === 'USER_MEMBERSHIP_ONETIME') {
        return 'Membership One-time'
      }

      if (this.usecase === 'USER_TIER_RECURRING') {
        return 'Tier Recurring'
      }

      if (this.usecase === 'USER_TIER_ONETIME') {
        return 'Tier One-time'
      }

      return 'Membership'
    },
  },
}
</script>

<style scoped>

</style>
